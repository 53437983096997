import React, {useState} from "react"
import { capitalizeFirstLetter, useUpdateSearchParams } from "../../utility/Utilities"
import { useCheckIsAdmin } from "../../utility/UserGroups"
import classNames from "classnames"
import { useFeedbackMessage } from "../../stores/FeedbackMessage"
import ConfirmModal from "../../components/ConfirmModal"
import Config from "../../utility/Config"
import { MdOutlineDeleteSweep } from "react-icons/md";
import { PiWarning } from "react-icons/pi";
import "./ClubConfig.css"

export function ClubConfigSelection ({clubConfig, configSection, setConfigSection}) {

    const [,isAdmin] = useCheckIsAdmin()
    const clubConfigOptions = ["starting page", "colors"]
    const team = clubConfig.team     

    return (
        <div className={classNames("club-config-option-cont", {"admin": isAdmin})}>
            <img src={team?.logo_url} alt="team logo" className="club-config-team-logo"/>
            {clubConfigOptions.map((s) => {
                return (
                    <div key={s} onClick={() => setConfigSection(s)} 
                        className={classNames("club-config-option", {"active": s === configSection})}>
                        {capitalizeFirstLetter(s)}
                    </div>
                )
            })}
        </div>
    )
}

export function ClubConfigControls ({editMode, setEditMode, generatePreviewConfig, openPreviewWindow, submitConfig, previewData, resetEdit}) {

    const [discardModalOpen, setDiscardModalOpen] = useState(false)

    const {showFeedback} = useFeedbackMessage()

    const openLivePreview = () => {
        // If there's no preview data at all, generate a new preview
        if (!previewData) generatePreviewConfig(true)
        // If there's preview data exists, only open the window
        else openPreviewWindow(previewData)
    }
    
    const copyPreviewLink = (e) => {
        e.stopPropagation()
        // If there's no preview data at all, generate a new preview
        if (!previewData) {
            generatePreviewConfig()
        } else {
            navigator.clipboard.writeText(`${Config.webHref}${previewData.route}?config_preview_id=${previewData.preview_id}`)
            showFeedback("success", "Preview link copied to clipboard")
        }
    }

    const discardEditModal = (
        <ConfirmModal 
            isOpen
            onClose={() => setDiscardModalOpen(false)} 
            onConfirm={resetEdit} 
            cancelText="Cancel" 
            confirmText="Discard"
            >
            <div className="confirm-icon-message">
                <div className="confirm-icon"><MdOutlineDeleteSweep/></div>
                <div className="confirm-title">Are you sure you want to discard the changes?</div>
            </div>
        </ConfirmModal>
    )

    const configControls = editMode ? (
        <div className="club-config-button-cont">
            <button onClick={openLivePreview} className="open-preview-link">
                Open live preview
            </button>
            <button onClick={copyPreviewLink} className="copy-preview-link">
                Copy preview link
            </button>
            <button onClick={() => setDiscardModalOpen(true)} className="discard-configuration">
                Discard
            </button>
            <button onClick={submitConfig} className="submit-configuration">
                Submit
            </button>
        </div>
    ) : (
        <div className="club-config-button-cont">
            <button onClick={() => setEditMode(true)} className="edit-configuration">
                Edit configuration
            </button>
        </div>
    )

    return (
        <div className="club-config-controls">
            {configControls}
            {discardModalOpen && discardEditModal}
        </div>
    )
}

export function ClubConfigTeamOptions ({allConfigs}) {

    const [searchParams, updateSearchParams,] = useUpdateSearchParams()    
    const teamParam = searchParams.get("team") 

    const allTeams = allConfigs.map((c) => {return c.team}) || []
    
    if (allTeams.length === 0) return null
    
    return (
        <div className="club-config-team-cont">
            {allConfigs.map((c) => {
                const {team, league} = c
                return (
                    <div key={team.id} className={classNames("club-config-team-single", {
                        "superettan": league === "superettan",
                        "legacy": c.special_state === "legacy",
                    })}>
                        <div onClick={() => updateSearchParams("team", team.id)}  className={classNames("club-config-team-select", {"active": team.id === parseInt(teamParam)})}>
                            <img src={team.logo_url} alt="team logo"/>
                            <div>{team.name}</div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export function ContrastSummary ({show=false, contrastSummary}) {
    if (!show || contrastSummary.length === 0) return null
    return (
        <div className="contrast-warning-cont">
            <div className="contrast-warning-title">
                <PiWarning/>
                Insufficient contrast warning
            </div>
            <div className="contrast-warning-list">
                {contrastSummary.map((c) => {
                    return (
                        <div key={c}>- {c}</div>
                    )
                })}
            </div>
        </div>
    )
} 