import React, {useEffect, useState, useRef, useMemo} from "react"
import { capitalizeFirstLetter } from "../../utility/Utilities"
import classNames from "classnames"
import { IoMdArrowDropright } from "react-icons/io";
import "./ClubConfig.css"
import { ContrastSummary } from "./ClubConfigUtils";

function ClubConfigColorBooleanSelection ({name, colors, edits, updateEdits, editMode}) {

    const getValue = () => {
        const override = edits.find((e) => e.name === name)
        if (override) return override.value
        else return colors[name]
    }

    const value = getValue()
    const isOriginal = value === colors[name]
    
    const onChangeColor = (name, value) => {
        const reset = value === colors[name]
        updateEdits({type: "color", name: name, value: value, reset: reset})
    }

    return (
        <div className="club-config-data">
            <div className="club-config-data-title">Dark mode</div>
            {editMode ? (
                <div className="club-config-data-value">
                    <div className="color-value-toggle">
                        <div onClick={() => onChangeColor(name, !value)} className={classNames("toggle-switch", {"active": !value})}>
                            <div className="toggle-button"></div>
                        </div>
                        {!isOriginal && (
                        <div className="set-to-original">
                            {/* <div className="club-config-dot"></div> */}
                            <div onClick={() => onChangeColor(name, colors[name])} className="config-reset-btn">
                                Reset
                            </div>
                        </div>
                    )}
                    </div>
                </div>
            ) : (
                <div className="club-config-data-value">{value ? "No" : "Yes"}</div>
            )}
        </div>
    )
}

function ClubConfigColorSelection ({name, colors, edits, updateEdits, editMode, advancedColors=false}) {

    const color = colors[name]
    
    const [newColor, setNewColor] = useState(color)

    const isOriginal = useMemo(() => newColor === colors[name], [newColor])

    const overriddenAdvancedColorValue = () => {
        if (!advancedColors) return null
        const override = edits.find((e) => e.name === name)
        return override?.value || null
    }
    const overriddenAdvancedColor = overriddenAdvancedColorValue()
    
    // Check if there's any edit that's active (not edit that reset to original)
    const hasColorsEdit = edits.filter((e) => e.type === "color" || e.type === "css").filter((e) => !e.reset).length !== 0

    useEffect(() => {
        if (advancedColors && overriddenAdvancedColor) return
        setNewColor(color)
    }, [color])

    useEffect(() => {
        if (!editMode) setNewColor(color)
    }, [editMode])

    useEffect(() => {
        if (newColor === "") setNewColor(undefined)
    }, [newColor])

    // If there's no more actively edited color, but the color is not original yet, then set all of them to original
    // Will be triggered when resetting all colors (clear all colors and css edits) 
    useEffect(() => {
        const inputIsHex = /^#[A-Fa-f0-9]{6}$/.test(newColor)
        const validInput = inputIsHex || !newColor
        if (!hasColorsEdit && !isOriginal && validInput) {
            const setToOriginal = setTimeout(() => {
                setNewColor(color || "")
            }, advancedColors ? 200 : undefined)
            return () => clearTimeout(setToOriginal)
        }
    }, [hasColorsEdit, isOriginal])

    const lightModeInEdit = edits.find((e) => e.name === "lightMode")?.value
    const backgroundInEdit = edits.find((e) => e.name === "background")
    const lightMode = lightModeInEdit === undefined ? colors.lightMode : lightModeInEdit
    const backgroundFollowsLightMode = name === "background" && !color
    const backgroundEditDisabled = name === "background" && lightMode

    const onChangeColor = (name, value=undefined) => {
        setNewColor(value ? value : "")
        if (value === "") value = undefined
        const type = advancedColors ? "css" : "color"
        const reset = value === colors[name]
        updateEdits({type: type, name: name, value: value, reset: reset})
    }

    // Reset background to undefined if background is picked and wanna return to light mode
    useEffect(() => {
        if (backgroundEditDisabled && backgroundInEdit) {
            onChangeColor("background", undefined)
        }
    }, [backgroundEditDisabled])
    
    // Typing color from text input
    const onTypeColor = (color) => {
        setNewColor(color)
        const type = advancedColors ? "css" : "color"
        const inputIsHex = /^#[A-Fa-f0-9]{6}$/.test(color)
        if (color === "") color = undefined
        const reset = color === colors[name]
        if (inputIsHex) updateEdits({type: type, name: name, value: color, reset: reset})
        if (color === undefined) updateEdits({type: type, name: name, value: undefined, reset: reset})
    }

    const colorNameTitle = name.replace(/--/g, "").replace(/-/g, " ")
    
    const checkShowRemove = () => {
        const removeAbleColor = ["header", "background"]
        if (!removeAbleColor.includes(name)) return false
        if (colors[name] && !!newColor) return true
    }
    const showRemove = checkShowRemove()

    const getLightModeValue = () => {
        const override = edits.find((e) => e.name === "lightMode")
        if (override) return override.value
        else return lightMode
    }
    // TODO better naming, being used in checking undefined background color
    const isLightModeValue = getLightModeValue()

    const isColorSchemeInAdvanced = name === "--color-scheme"
    const isBgColorInAdvanced = name === "--bg-color"
    if (isColorSchemeInAdvanced || isBgColorInAdvanced) return null

    // Variable for undefined color (header and background)
    let noValueColor
    if (name === "header") noValueColor = "#000000"
    if (name === "background" && !newColor) noValueColor = isLightModeValue ? "#FFFFFF" : "#000000"

    let defaultNonEditColor = (
        <div className="data-color">
            {color && (
                <div style={{backgroundColor: color}} className="config-color-box"></div>
            )}
            {color || "None"}
        </div>
    )

    let defaultEditColor = (
        <div className="data-color">
            <input 
                type="color"
                disabled={!editMode}
                value={newColor || noValueColor || "#"}
                onChange={(e) => onChangeColor(name, e.target.value)}
                className={classNames("data-color-box", {
                    "active": editMode,
                })}
                />
            <input 
                type="text" 
                value={newColor}
                onChange={(e) => onTypeColor(e.target.value)}
                className="new-color-input"/>
            {!isOriginal && (
                <div onClick={() => onChangeColor(name, colors[name])} className="config-reset-btn">
                    Reset
                </div>
            )}
            {showRemove && (
                <div onClick={() => onChangeColor(name, "")} className="config-reset-btn">
                    Remove
                </div>
            )}
        </div>
    )

    if (backgroundFollowsLightMode) defaultNonEditColor = (
        <div className="background-light-mode-info">
            Dark mode {lightMode ? "inactive" : "active"}: Background is {lightMode ? "white" : "black"}
        </div>
    )

    if (backgroundEditDisabled) defaultEditColor = (
        <div className="background-light-mode-info">
            Activate dark mode to edit background color
        </div>
    )

    return (
        <div className="club-config-data">
            <div className="club-config-data-title">
                {capitalizeFirstLetter(colorNameTitle)}
            </div>
            <div className="club-config-data-value color">
                {editMode ? defaultEditColor : defaultNonEditColor}
            </div>
        </div>
    )
}

function ClubConfigColors ({colors, css, originalCss, setCssVariables, edits, setEdits, updateEdits, editMode, configSection, contrastSummary, previewWindowIsOpen, updatePreviewConfig}) {
    
    const [openAdvancedColors, setOpenAdvancedColors] = useState(false)

    // const { ref: bottomListRef, inView: bottomListInView } = useInView({initialInView: false});
    const colorsSectionRef = useRef()

    useEffect(() => {
        if (!editMode && openAdvancedColors) setOpenAdvancedColors(false)
    }, [editMode])

    useEffect(() => {
        if (configSection === "colors") colorsSectionRef.current?.scrollIntoView({behavior: "smooth"})
    }, [configSection])

    if (!colors || !css) return null

    const resetAllColors = () => {
        const originalColorsObject = {
            colors: colors,
            css: originalCss,
        }
        const editsWithoutColors = edits.filter((e) => e.type !== "color").filter((e) => e.type !== "css")
        setCssVariables(originalCss)
        setEdits(editsWithoutColors)
        if (previewWindowIsOpen) updatePreviewConfig(originalColorsObject)
    }
    
    const colorsNames = ["primary", "accent", "contrast", "header", "background"]
    
    const colorsOverrides = (
        <div className={classNames("colors-overrides-cont", {"show": openAdvancedColors})}>
            <div className="colors-overrides-disclaimer">
                We do our best to create a great color scheme using the colors you provide, applying them to many page elements. 
                However, not all combinations will work perfectly. Some colors may not be used, 
                or we might adjust their placement to ensure good contrast. This is important from an accessibility point of view.
                <br />
                <br />
                If needed, you can manually override colors, but be careful—this could cause issues like text becoming hard to read, 
                such as white text on a yellow background.
            </div>
            <div className="colors-overrides-title">
                Color overrides
                <div onClick={() => setOpenAdvancedColors(!openAdvancedColors)} className="club-config-dropdown-toggle">
                    {openAdvancedColors ? "Hide" : "Show"} <IoMdArrowDropright />
                </div>
            </div>
            <div className="colors-overrides-list">
                {Object.entries(css).map(([key,]) => {
                    return (
                        <ClubConfigColorSelection 
                            key={key} 
                            name={key} 
                            colors={css}
                            edits={edits}
                            updateEdits={updateEdits}
                            editMode={editMode}
                            advancedColors/>
                    )
                })}
            </div>
        </div>  
    )

    const hasColorsEdit = edits.filter((e) => e.type === "color" || e.type === "css").filter((e) => !e.reset).length !== 0
        
    return (
        <div ref={colorsSectionRef} className="club-config-section">
            <div className="club-config-section-title space-between">
                Colors
                {hasColorsEdit && (
                    <div onClick={resetAllColors} className="config-reset-btn">Reset all colors</div>
                )}
            </div>
            {colorsNames.map((n) => {
                return (
                    <ClubConfigColorSelection 
                        key={n}
                        name={n}
                        colors={colors}
                        edits={edits}
                        updateEdits={updateEdits}
                        editMode={editMode}/>
                )
            })}
            <ClubConfigColorBooleanSelection 
                name={"lightMode"}
                colors={colors}
                edits={edits}
                updateEdits={updateEdits}
                editMode={editMode}
                />
            <ContrastSummary show={editMode} contrastSummary={contrastSummary}/>
            {colorsOverrides}
        </div>
    )
}

export default ClubConfigColors