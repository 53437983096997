import React, {useEffect, useState} from "react"
import { useBackend } from "../../utility/Backend"
import ClubConfigSection from "./ClubConfigSection"
import { ClubConfigTeamOptions, ClubConfigSelection } from "./ClubConfigUtils"
import { useUpdateSearchParams } from "../../utility/Utilities"
import { useCheckIsAdmin } from "../../utility/UserGroups"
import Page, { LoadingPage } from "../../components/Page"
import { Helmet } from "react-helmet-async"
import { useGetUsersGroups } from "../../utility/UserGroups"
import Config from "../../utility/Config"
import { useNavigate } from "react-router-dom";
import { useNavigationBlock } from "../Layout/CustomRouter"
import "./ClubConfig.css"

function ClubConfigMain () {
    
    const [,isAdmin] = useCheckIsAdmin()
    const usersUserGroups = useGetUsersGroups()
    const [searchParams, updateSearchParams,] = useUpdateSearchParams()    
    const teamParam = searchParams.get("team")

    const {data, mutate} = useBackend("/fotbollplay/clubconfig?nocache")
    const clubConfigData = data || []

    const allsvenskanConfigs = clubConfigData.allsvenskan || []
    const superettanConfigs = clubConfigData.superettan || []
    const otherConfigs = clubConfigData.other || []

    const allConfigs = [...allsvenskanConfigs, ...superettanConfigs, ...otherConfigs]
    
    const firstConfigTeamId = isAdmin && allConfigs[0]?.id 
    
    const [editMode, setEditMode] = useState(false)
    const [configSection, setConfigSection] = useState("starting page")

    // Set team param for admin on first render (first team on the list), because admin can open all clubs' config
    useEffect(() => {
        if (!isAdmin || teamParam) return
        updateSearchParams("team", firstConfigTeamId)
    }, [teamParam, firstConfigTeamId])

    useEffect(() => {
        if (configSection !== "starting page") setConfigSection("starting page")
    }, [teamParam])

    useNavigationBlock("The club configuration has unsaved changes. Are you sure you wish to leave?", editMode)
    
    if (!data || allConfigs.length === 0) return <LoadingPage/>

    // ADMIN, get config from team param
    const configFromParam = allConfigs.find((c) => c.id === parseInt(teamParam))

    // NON ADMIN, get the club config from the team's user group id
    const teamConfig = () => {
        const userGroupId = usersUserGroups[0]?.id
        const teamConfig = allConfigs.find((c) => c.usergroup_id === userGroupId)
        return teamConfig
    }

    const clubConfig = isAdmin ? configFromParam : teamConfig()
    
    if (!clubConfig) return <LoadingPage/> 

    return (
        <Page centered title="Club config">
            <Helmet>
                <title>Club Config</title>
            </Helmet>
            <div className="club-config-main">
                {isAdmin && (
                    <ClubConfigTeamOptions allConfigs={allConfigs}/>
                )}
                <ClubConfigSelection 
                    clubConfig={clubConfig}
                    configSection={configSection}
                    setConfigSection={setConfigSection}
                    />
                <ClubConfigSection 
                    key={teamParam} 
                    clubConfig={clubConfig} 
                    editMode={editMode} 
                    setEditMode={setEditMode} 
                    configSection={configSection}
                    mutate={mutate}
                    />
            </div>
        </Page>
    )
}

function ClubConfig () {

    const navigate = useNavigate()
    const isSef = Config.association === "SEF"

    // Redirect to home, when it is not SEF, and manually writing club config URL in the browser
    useEffect(() => {        
        if (!isSef) navigate(Config.defaultPath, {replace: true})
    }, [])

    if (!isSef) return null

    return <ClubConfigMain/>
}

export default ClubConfig