import React, {useState} from "react"
import { useBackend } from "../../utility/Backend";
import Backend from "../../utility/Backend";
import { useCheckIsAdmin } from "../../utility/UserGroups";
import { LoadingPage } from "../../components/Page";
import CustomModal from "../../components/CustomModal";
import { useFeedbackMessage } from "../../stores/FeedbackMessage";
import { useMutateByRegex } from "../../utility/Utilities";
import { showErrorMessage } from "../../utility/Utilities";
import {Link} from "react-router-dom";
import "./Teams.css";

function MerchantSubscription ({merchantId}) {

    const {data} = useBackend(merchantId ? "/cee_merchant/" + merchantId + "/subscription?nocache" : "")
    
    if (!data) return "-"

    const subscriptions = data?.subscriptions

    if (subscriptions.length === 0) return "-"
    
    const renderSubscriptions = subscriptions.map((s) => {
        const pricePlan = s.price_plans[0]
        return (
            <div key={s.id} className="subscription-info-cont">
                <div className="subscription-info-name">
                    {s.name}
                </div>
                <div className="subscription-info">
                    <div className="subscription-info-title">
                        Price : 
                    </div>
                    <div className="subscription-info-value">
                        {pricePlan.price_amount} {pricePlan.price_currency}
                    </div>
                </div>
                <div className="subscription-info">
                    <div className="subscription-info-title">
                        Plan : 
                    </div>
                    <div className="subscription-info-value">
                        {pricePlan.name}
                    </div>
                </div>
            </div>
        )
    })
    
    return (
        <div>{renderSubscriptions}</div>
    )
}

function TeamFotbollPlay ({teamId}) {

    const [isFsAdmin,] = useCheckIsAdmin()
    const {showFeedback} = useFeedbackMessage()
    const mutateByRegex = useMutateByRegex()

    const [addingMerchant, setAddingMerchant] = useState(false)
    const [merchantId, setMerchantId] = useState("")
    const [error, setError] = useState(null)

    const {data} = useBackend("/fotbollplay/team/" + teamId + "/clubconfig?nocache")

    if (!data) return <LoadingPage/>

    const clubConfig = data
    
    const {channels, merchants, team, route} = clubConfig
    
    const teamChannels = channels.length !== 0 ? (
        <div className="team-channels-cont">
            {channels.map((c) => {
                return <div key={c.id}>{c.name}</div>
            })}
        </div>
    ) : "-"

    const openAddMerchant = () => {
        setAddingMerchant(true)
    }

    const onAddMerchant = () => {
        const newMerchantProps = {cee_merchant_id: merchantId}
        Backend.post("/fotbollplay/team/" + team.id + "/merchant", JSON.stringify(newMerchantProps))
            .then(({error}) => {
                if (error) {
                    setError(error)
                    showFeedback("warning", error)
                    console.error("Failed to POST", error)
                } else {
                    showFeedback("success", "Merchant created successfully");
                    console.log("Merchant created");
                    mutateByRegex(/^\/fotbollplay\//)
                    setAddingMerchant(false)
                }
            })
    }

    const addMerchantForm = addingMerchant && (
        <CustomModal isOpen onRequestClose={() => setAddingMerchant(false)} className="confirm-modal">
            <div className="add-merchant-cont">
                <form action="" method="" className="add-merchant-form">
                    <div className="add-merchant-title">Add Merchant</div>
                    <div className="input-container">
                        <label htmlFor="current-password" className="input-title">Merchant id</label>
                        <input
                            id="current-password"
                            type="password"
                            onChange={(e) => setMerchantId(e.target.value)}
                            value={merchantId}
                            autoComplete="current-password"
                            className="upload-title-input"/>
                    </div>
                    <div className="change-password-warning">
                        {showErrorMessage(error, error)}
                    </div>        
                </form>
                <div className="confirm-cancel-btn-cont full padding-bottom">
                    <button type="button" onClick={onAddMerchant} className="green-btn">Create</button>
                    <button type="button" onClick={() => setAddingMerchant(false)}>Cancel</button>
                </div>
            </div>
        </CustomModal>
    )

    const addMerchantAvailable = isFsAdmin && !merchants[0]

    // Only for FSA
    const addClubMerchant = addMerchantAvailable && (
        <div className="confirm-cancel-btn-cont margin-top">
            <button onClick={openAddMerchant}>
                {/* <FiEdit3 className="icon-in-btn"/> */}
                Add Merchant
            </button>
            {addMerchantForm}
        </div>
    )

    return (
        <div className="data-info-cont">
            <div className="data-info-head-title">Fotboll Play</div>
            <div className="data-info">
                <div className="data-info-title">Fotboll Play</div>
                <div className="data-info-value">
                    <a href={`https://fotbollplay.se${route}`} target="_blank" rel="noreferrer">
                        {`https://fotbollplay.se${route}`}
                    </a>
                </div>
            </div>
            <div className="data-info">
                <div className="data-info-title">Channels</div>
                <div className="data-info-value">{teamChannels}</div>
            </div>
            <div className="data-info">
                <div className="data-info-title">Merchant</div>
                <div className="data-info-value">{merchants[0]?.name || "-"}</div>
            </div>
            <div className="data-info">
                <div className="data-info-title">Merchant ID</div>
                <div className="data-info-value">{merchants[0]?.id || "-"}</div>
            </div>
            <div className="data-info">
                <div className="data-info-title">Subscriptions</div>
                <div className="data-info-value"><MerchantSubscription merchantId={merchants[0]?.id}/></div>
            </div>
            <div className="data-info">
                <div className="data-info-title">Club config</div>
                <div className="data-info-value">
                    <Link to={`/club_config?team=${team.id}`}>Configure club config</Link>
                </div>
            </div>
            {addClubMerchant}
        </div> 
    )
}

export default TeamFotbollPlay