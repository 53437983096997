import React, {useEffect, useState, useRef, useMemo} from "react"
import UploadImage from "../../components/UploadImage"
import classNames from "classnames"
import "./ClubConfig.css"

function ClubConfigHeroImage ({
    heroImage, 
    edits, 
    editMode, 
    onChangeHero, 
    updateHeroImage, 
    heroImageSize, 
    setHeroImageSize, 
    imageObjectPosition
}) {

    const heroImageRef = useRef()

    const [heroWidth, setHeroWidth] = useState(null)
    const [heroHeight, setHeroHeight] = useState(null)

    const convertPositionValue = (position) => {
        if (!position || position === undefined) return 50
        else if (position === "center") return 50
        else if (position === "top") return 0
        else if (position === "bottom") return 0
        else return parseInt(position)
    }

    const horizontal = useMemo(() => {return convertPositionValue(imageObjectPosition.split("%")[0])}, [imageObjectPosition, editMode])
    const vertical = useMemo(() => {return convertPositionValue(imageObjectPosition.split("%")[1])}, [imageObjectPosition, editMode])
    
    const [editingHeroImage, setEditingHeroImage] = useState(false)

    const getValue = () => {
        const override = edits.find((e) => e.name === "url")
        if (override) return override.value
        else return heroImage["url"]
    }
    
    const url = getValue()

    const originalUrl = url === heroImage.url

    const onChangeImage = (imgAndUrl) => {
        updateHeroImage(imgAndUrl.imageFile, "hero")
    }

    useEffect(() => {
        if (!heroImageRef.current) return
        const setImageSize = () => {
            setHeroWidth(heroImageRef.current.width);
            setHeroHeight(heroImageRef.current.height);
        }
        heroImageRef.current.addEventListener("load", setImageSize)
        return () => heroImageRef.current?.removeEventListener("load", setImageSize)
    }, [heroImageRef.current, editMode])
    
    // Aspect ratio for mobile around 3.5:4 (not 100% accurate since there are many variations of screen and resolution size in laptop and mobile)
    const activeHeroAreaMobile = Math.min(heroWidth, (heroHeight * 3.5)/4)
    const uncoveredHeroAreaMobile = heroWidth - activeHeroAreaMobile
    // Aspect ratio for desktop around 16:5.9 (not 100% accurate since there are many variations of screen and resolution size in laptop and mobile)
    const activeHeroAreaDesktop = (heroWidth * 5.9) / 16
    const uncoveredHeroAreaDesktop = heroHeight - activeHeroAreaDesktop
    
    let activeHeroAreaStyle = {}
    let firstShadeStyle = {}
    let secondShadeStyle = {}

    if (heroImageSize === "mobile") {
        activeHeroAreaStyle = {width: `${activeHeroAreaMobile}px`,left: `${(uncoveredHeroAreaMobile * horizontal)/100}px`}
        firstShadeStyle = {width: `${(uncoveredHeroAreaMobile * horizontal)/100}px`}
        secondShadeStyle = {width: `${(uncoveredHeroAreaMobile * (100-horizontal))/100}px`}
    }

    if (heroImageSize === "desktop") {
        activeHeroAreaStyle = {height: `${activeHeroAreaDesktop}px`, top: `${(uncoveredHeroAreaDesktop * vertical)/100}px`}
        firstShadeStyle = {height: `${(uncoveredHeroAreaDesktop * vertical)/100}px`}
        secondShadeStyle = {height: `${(uncoveredHeroAreaDesktop * (100-vertical))/100}px`}
    }

    return (
        <div className="club-config-data align-start">
            <div className="club-config-data-title">Hero image</div>
            <div className="club-config-data-value">
                <div className="club-config-hero">
                    <div className="hero-image-size-cont">
                        <div 
                            onClick={() => setHeroImageSize("mobile")} 
                            className={classNames("hero-image-size", {"active": heroImageSize === "mobile"})}>
                            Mobile
                        </div>
                        <div 
                            onClick={() => setHeroImageSize("desktop")} 
                            className={classNames("hero-image-size", {"active": heroImageSize === "desktop"})}>
                            Desktop
                        </div>
                        <div 
                            onClick={() => setHeroImageSize("full")} 
                            className={classNames("hero-image-size", {"active": heroImageSize === "full"})}>
                            Full size
                        </div>
                    </div>
                    <div className={classNames("club-config-hero-img", {
                        "mobile": heroImageSize === "mobile",
                        "desktop": heroImageSize === "desktop",
                    })}>
                        <img ref={heroImageRef} src={url} alt="hero" style={{objectPosition: imageObjectPosition}}/>
                        <div style={activeHeroAreaStyle} className="active-box"></div>
                        <div style={firstShadeStyle} className="first-shade"></div>
                        <div style={secondShadeStyle} className="second-shade"></div>
                    </div>
                    <div className={classNames("club-config-hero-change", {"active": editMode})}>
                        <div onClick={() => setEditingHeroImage(true)} className="club-config-edit">Change</div>
                        {!originalUrl && (
                            <div onClick={() => onChangeHero("url", heroImage.url, true)} className="config-reset-btn">
                                Reset
                            </div>
                        )}
                    </div>
                    {editingHeroImage && (
                        <UploadImage 
                            onClose={() => setEditingHeroImage(false)}
                            onChange={onChangeImage}/>
                    )}
                </div>
            </div>
        </div>
    )
}

function ClubConfigHeroLogo ({heroImage, edits, editMode, onChangeHero, updateHeroImage}) {

    const [editingHeroLogo, setEditingHeroLogo] = useState(false)

    const getValue = (name) => {
        const override = edits.find((e) => e.name === name)
        if (override) return override.value
        else return heroImage[name]
    }
    
    const heroLogo = getValue("logo_url")

    const originalHeroLogo = heroLogo === heroImage.logo_url

    const onChangeImage = (imgAndUrl) => {
        updateHeroImage(imgAndUrl.imageFile, "hero_logo")
    }

    return (
        <div className="club-config-data">
            <div className="club-config-data-title">Hero logo</div>
            <div className="club-config-data-value">
                <div className="club-config-hero-logo">
                    {heroLogo ? (
                        <img src={heroLogo} alt="hero logo" className="club-config-hero-logo-img"/>
                    ) : (
                        <div className="grey-color-text">
                            None (Default)
                        </div>
                    )}
                    <div className={classNames("club-config-hero-change", {"active": editMode})}>
                        <div onClick={() => setEditingHeroLogo(true)} className="club-config-edit">
                            {heroLogo ? "Change" : "Add"}
                        </div>
                        {!originalHeroLogo && (
                            <div onClick={() => onChangeHero("logo_url", heroImage.logo_url, true)} className="config-reset-btn">
                                Reset
                            </div>
                        )}
                        {(heroImage.logo_url && heroLogo) && (
                            <div onClick={() => onChangeHero("logo_url", null)} className="config-reset-btn">
                                Remove
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {editingHeroLogo && (
                <UploadImage 
                    onClose={() => setEditingHeroLogo(false)}
                    onChange={onChangeImage}/>
            )}
        </div>
    )
}

function ClubConfigHeroPosition ({heroImage, edits, editMode, onChangeHero, setImageObjectPosition, setHeroImageSize}) {

    const getValue = () => {
        const override = edits.find((e) => e.name === "position")
        if (override) return override.value
        else return heroImage["position"]
    }
    
    const position = getValue("position")

    const convertPositionValue = (position) => {
        const percentageRegex = /^(100|[1-9]?[0-9])%$/;
        const isPercentage = percentageRegex.test(position);

        if (!position || position === undefined) return "50"
        else if (position === "center") return "50"
        else if (position === "top") return "0"
        else if (isPercentage) return position.replace("%", "")
    }

    const oriPositionParts = heroImage.position.split(" ");
    const [oriHorizontal, oriVertical] = oriPositionParts.length > 1 ? [convertPositionValue(oriPositionParts[0]), convertPositionValue(oriPositionParts[1])] : ["50", convertPositionValue(oriPositionParts[0])]
    const positionParts = position.split(" ");
    const [horizontal, vertical] = positionParts.length > 1 ? [convertPositionValue(positionParts[0]), convertPositionValue(positionParts[1])] : ["50", convertPositionValue(positionParts[0])]
    
    const [horizontalValue, setHorizontalValue] = useState(horizontal)
    const [verticalValue, setVerticalValue] = useState(vertical)

    const originalHorizontal = horizontalValue === oriHorizontal
    const originalVertical = verticalValue === oriVertical

    useEffect(() => {
        if (!editMode) {
            setHorizontalValue(horizontal)
            setVerticalValue(vertical)
        }
    }, [editMode])

    useEffect(() => {
        setImageObjectPosition (`${horizontalValue}% ${verticalValue}%`)
    }, [horizontalValue, verticalValue])

    const onResetPosition = (direction, value, reset=false) => {
        if (direction === "horizontal") {
            setHorizontalValue(value)
            onChangeHero("position", `${value}% ${verticalValue}%`, reset)
        }
        if (direction === "vertical") {
            setVerticalValue(value)
            onChangeHero("position", `${horizontalValue}% ${value}%`, reset)
        }
    }

    return (
        <div className="club-config-data align-start">
            <div className="club-config-data-title">Position</div>
            <div className="club-config-data-value">
                <div className="hero-position-cont">
                    <div className="hero-position-part">
                        <div className="hero-position-input-cont">
                            <input 
                                type="range" 
                                min="0" 
                                max="100" 
                                step="10" 
                                disabled={!editMode}
                                value={horizontalValue} 
                                onChange={(e) => setHorizontalValue(e.target.value)}
                                onMouseUp={() => onChangeHero("position", `${horizontalValue}% ${verticalValue}%`)} 
                                onMouseDown={() => setHeroImageSize("mobile")}
                                className={classNames("hero-position-input", {"disabled": !editMode})}
                                />
                            <div className="hero-position-indicator start">Left</div>
                            <div className="hero-position-indicator mid">Center</div>
                            <div className="hero-position-indicator end">Right</div>
                        </div>
                        {!originalHorizontal && (
                            <div onClick={() => onResetPosition("horizontal", oriHorizontal, true)} className="config-reset-btn">
                                Reset
                            </div>
                        )}
                    </div>
                    <div className="hero-position-part">
                        <div className="hero-position-input-cont">
                            <input 
                                type="range" 
                                min="0" 
                                max="100" 
                                step="10" 
                                disabled={!editMode}
                                value={verticalValue} 
                                onChange={(e) => setVerticalValue(e.target.value)}
                                onMouseUp={() => onChangeHero("position", `${horizontalValue}% ${verticalValue}%`)} 
                                onMouseDown={() => setHeroImageSize("desktop")}
                                className={classNames("hero-position-input vertical", {"disabled": !editMode})}
                                />
                            <div className="hero-position-indicator start">Bottom</div>
                            <div className="hero-position-indicator mid">center</div>
                            <div className="hero-position-indicator end">Top</div>
                        </div>
                        {!originalVertical && (
                            <div onClick={() => onResetPosition("vertical", oriVertical, true)} className="config-reset-btn">
                                Reset
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

function ClubConfigHeroMessage ({heroImage, edits, editMode, updateEdits}) {

    const [newHeroMessage, setNewHeroMessage] = useState("")

    const getValue = () => {
        const override = edits.find((e) => e.name === "message")
        if (override) return override.value
        else return heroImage.message || ""
    }

    const heroMessage = getValue()
    
    useEffect(() => {
        if (heroImage.message) setNewHeroMessage(heroImage.message)
    }, [heroImage.message])

    useEffect(() => {
        updateEdits({type: "heroImage", name: "message", value: newHeroMessage})
    }, [newHeroMessage])

    useEffect(() => {
        if (!editMode) setNewHeroMessage(heroMessage)
    }, [editMode])

    const originalHeroMessage = heroImage.message ? newHeroMessage === heroImage.message : !newHeroMessage

    const onResetHeroMessage = () => {
        if (heroImage.message) setNewHeroMessage(heroImage.message)
        else setNewHeroMessage("")
        updateEdits({type: "heroImage", name: "message", value: heroImage.message, reset: true})
    }

    return (
        <div className="club-config-data">
            <div className="club-config-data-title">Hero message</div>
            <div className="club-config-data-value">
                {editMode ? (
                    <div className="club-config-message">
                        <input 
                            type="text"
                            value={newHeroMessage}
                            onChange={(e) => setNewHeroMessage(e.target.value)} 
                            className="hero-message-input"
                            />
                        {!originalHeroMessage && (
                            <div onClick={onResetHeroMessage} className="config-reset-btn">
                                Reset
                            </div>
                        )}
                    </div>
                ) : (
                    <div className={classNames("club-config-message", {"grey-color-text": !heroMessage})}>
                        {heroMessage || "None (Default)"}
                    </div>
                )}
            </div>
        </div>
    )
}

function ClubConfigHero ({heroImage, edits, updateEdits, editMode, updateHeroImage, configSection}) {
    
    const startingPageSectionRef = useRef()
    
    const [imageObjectPosition, setImageObjectPosition] = useState("0% 0%")
    const [heroImageSize, setHeroImageSize] = useState("full")

    useEffect(() => {
        if (editMode) setHeroImageSize("desktop")
        else setHeroImageSize("full")
    }, [editMode])

    useEffect(() => {
        if (configSection === "starting page") startingPageSectionRef.current?.scrollIntoView({behavior: "smooth"})
    }, [configSection])

    if (!heroImage) return null

    const onChangeHero = (name, value, reset=false) => {
        updateEdits({type: "heroImage", name: name, value: value, reset: reset})
    }
    
    return (
        <div ref={startingPageSectionRef} className="club-config-section">
            <div className="club-config-section-title">Starting page</div>
            <ClubConfigHeroImage 
                heroImage={heroImage} 
                edits={edits} 
                editMode={editMode}
                onChangeHero={onChangeHero}
                updateHeroImage={updateHeroImage}
                heroImageSize={heroImageSize} 
                setHeroImageSize={setHeroImageSize} 
                imageObjectPosition={imageObjectPosition}
                />
            <ClubConfigHeroPosition
                heroImage={heroImage}
                edits={edits}
                editMode={editMode}
                onChangeHero={onChangeHero}
                setImageObjectPosition={setImageObjectPosition}
                setHeroImageSize={setHeroImageSize}
                />
            <ClubConfigHeroLogo
                heroImage={heroImage}
                edits={edits}
                editMode={editMode}
                onChangeHero={onChangeHero}
                updateHeroImage={updateHeroImage}
                />
            <ClubConfigHeroMessage
                heroImage={heroImage}
                edits={edits}
                editMode={editMode}
                updateEdits={updateEdits}
                />
        </div>
    )
}

export default ClubConfigHero